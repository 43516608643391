<template>
    <div id="loan-request">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card class="mt-2 mb-2">
                    <div class="vx-row">
                        <div class="vx-col w-full mb-4">
                            <h1 class="big-title-bg extrabold mb-4">Solicitud de crédito</h1>
                            <vs-progress class="mb-2" :percent="calculatePercent" :height="10" color="primary"></vs-progress>
                            <p>PASO {{ step }} 
                                <template v-if="getTotalSteps > 0">
                                    DE {{ getTotalSteps }}
                                </template>
                            </p>
                        </div>
                        <div class="vx-col w-full">                            
                            <!-- ¿qué credito deseas solicitar -->
                            <div v-if="onboardingStep['1_loan_type'] == null" id="1_loan_type">
                                <h4 class="mb-2">Tipo de crédito</h4>
                                <p>Elige el tipo de crédito que deseas solicitar con RedGirasol.</p>
                                <vs-divider></vs-divider>
                                <p class="bold black mb-4">¿Qué crédito deseas?</p>
                                <div class="vx-row">
                                    <div v-if="showLoanOptions(item) && showOptionByProject(item)" class="vx-col w-2/6 mb-8 account-button mt-2" v-for="(item, index) in loanTypes" :key="index">
                                        <rg-radio 
                                            v-if="showOptionByProject(item)"
                                            class="w-full" 
                                            groupName="loanType" 
                                            :label="item.label"
                                            :value="item.value" 
                                            :desc="item.desc" 
                                            v-model="loanType" />
                                    </div>
                                </div>
                                <div class="vx-row justify-end mt-12">
                                    <!-- <div class="vx-col">
                                        <vs-button @click.stop="prevStep('1_set_loan_type')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                    </div> -->
                                    <div class="vx-col">
                                        <vs-button @click.stop="nextStep('1_set_loan_type')" color="dark">Continuar</vs-button>
                                    </div>
                                </div>
                            </div>

                            <!-- dependiento de su tipo de credito y de persona, este flujo cambia -->
                            <div v-if="isEcotechnologiesLoan && initialInfoCompleted">
                                <!-- selecciona su ecotecnología -->
                                <div v-if="onboardingStep['3_family_group'] == null">
                                    <h4 class="mb-2">Tipo de producto</h4>
                                    <p>Confirma la información para tu préstamo.</p>
                                    <vs-divider></vs-divider>
                                    <p class="bold black mb-4">¿Cuál producto o eco-tecnología?</p>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/4 mb-8 mt-2"
                                            v-for="(item, index) in getTechnologiesFormat" :key="index">
                                            <rg-radio class="w-full" groupName="familyGroupSelected" :label="item.label"
                                                :value="item.value" :desc="item.desc" v-model="familyGroupSelected" />
                                        </div>
                                    </div>
                                    <div class="vx-row justify-end mt-12">
                                        <div class="vx-col">
                                            <vs-button @click.stop="prevStep('2_set_person_type')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                        </div>
                                        <div class="vx-col">
                                            <vs-button @click.stop="nextStep('3_set_family_group')" color="dark">Continuar</vs-button>
                                        </div>
                                    </div>
                                </div>

                                <!-- información del promotor, si tiene o no el flujo cambia -->
                                <div v-if="familyGroupCompleted && !supplierInformationCompleted && isSFV">
                                    <!-- sí no tiene un supplierId precargado  -->
                                    <div v-if="continueWithReferalSupplier == 0">
                                        <h4 class="mb-2">Información de tu promotor</h4>
                                        <p>Confirma la información de tu promotor</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4">¿Cuentas con algún promotor para realizar este proyecto?</p>
                                        <div class="vx-row">
                                            <div class="vx-col w-2/6 mb-8 mt-2"
                                                v-for="(item, index) in hasSupplierOptions" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="hasSupplierOptions" :label="item.label"
                                                    :value="item.value" v-model="hasSupplier" />
                                            </div>

                                            <!-- info del promotor -->
                                            <div class="vx-col w-full mt-base ml-4 border-left">

                                                <!-- Sí ya tiene un promotor, les pedimos su nombre y teléfono -->
                                                <div v-if="hasSupplier == 1" :key="'supplierDataTest'">
                                                    <p class="bold black mb-4">Genial, por favor déjanos los datos del promotor que deseas:</p>
                                                    <div class="vx-row">
                                                        <div :class="colClass">
                                                            <vs-input
                                                                @input="(val) => (supplierName = supplierName.toUpperCase())"
                                                                class="w-full"
                                                                label="Nombre comercial"
                                                                name="supplierName"
                                                                v-validate.persist="requiredRules"
                                                                v-model.trim="supplierName"
                                                                :danger="hasError('supplierName')"
                                                                :danger-text="errorText('supplierName')"
                                                                :success="isSuccess('supplierName')"
                                                                icon-pack="feather"
                                                                :placeholder="requiredPlaceholder"
                                                            />
                                                        </div>
                                                        <div :class="colClass">
                                                            <label class="vs-input--label">Número de contacto</label
                                                            >
                                                            <div class="flex">
                                                                <vue-country-code
                                                                    name="dialCode"
                                                                    class="vs-custom"
                                                                    data-vv-name="dialCode"
                                                                    :enableSearchField="true"
                                                                    searchPlaceholderText="Buscar código de país"
                                                                    :enabledCountryCode="true"
                                                                    @onSelect="onSelect"
                                                                    :preferredCountries="['MX']"
                                                                    :danger="hasError('dialCode')"
                                                                    :danger-text="errorText('dialCode')"
                                                                    :success="isSuccess('dialCode')">
                                                                </vue-country-code>
                                                                <vs-input
                                                                    class="w-full"
                                                                    label=""
                                                                    name="supplierPhone"
                                                                    type="tel"
                                                                    v-validate.persist="'required|numeric|min:10|max:10'"
                                                                    v-model.lazy="supplierPhone"
                                                                    :danger="hasError('supplierPhone')"
                                                                    :danger-text="errorText('supplierPhone')"
                                                                    :success="isSuccess('supplierPhone')"
                                                                    icon-pack="feather"
                                                                    :placeholder="requiredPlaceholder"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="hasSupplier == 2">
                                                    <p>
                                                        <i>
                                                            ¡Claro, no te preocupes!
                                                            <br>
                                                            Te asignaremos un promotor según tus necesidades y objetivos.
                                                        </i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vx-row justify-end mt-12">
                                            <!-- <div class="vx-col">
                                                <vs-button @click.stop="prevStep('3_set_family_group')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div> -->
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('4_set_supplier_information')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <h4 class="mb-2">Información de tu promotor</h4>
                                        <p>Este promotor te refirió, confirma si deseas realizar este proyecto con él.</p>
                                        <vs-divider></vs-divider>
                                        <div class="vx-row mt-base mb-base">
                                            <div class="vx-col sm:w-full md:w-1/3 lg: 3/12">
                                                <div class="border-primary border-solid">
                                                    <div class="flex">
                                                        <div class="mr-2">
                                                            <span>
                                                                <vs-icon icon="perm_identity" size="medium" color="primary"></vs-icon>
                                                            </span> 
                                                        </div>
                                                        <div>
                                                            <h4>{{ ApplicantSupplierReferredData.tradename }}</h4>
                                                            <p>Email: <span class="bold">{{ ApplicantSupplierReferredData.email }}</span></p>
                                                            <p>Teléfono: <span class="bold">{{ ApplicantSupplierReferredData.phone }}</span></p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-2/3 lg: 7/12 border-left">
                                                <p class="bold black mb-4">¿Deseas realizar este proyecto con éste promotor?</p>
                                                <div class="vx-row">
                                                    <div class="vx-col w-1/3 mb-8 mt-2"
                                                        v-for="(item, index) in wantsToContinueWithSupplierOptions" :key="index">
                                                        <rg-radio class="w-full" groupName="continueWithReferalSupplier" :label="item.label"
                                                            :value="item.value" :desc="item.desc" v-model="continueWithReferalSupplier" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('3_set_family_group')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('5_set_supplier_referal')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- información general de consumo -->
                                <div v-if="supplierInformationCompleted">
                                    <h4 class="mb-2">Información adicional</h4>
                                    <p v-if="isSFV">Confirma la información de tu proyecto.</p>
                                    <p v-if="!isSFV">Confirma la información de tu vehículo.</p>
                                    <vs-divider></vs-divider>
                                    
                                    <!-- PREGUNTAR SÍ EL CRÉDITO SERA COMO PF O PFAE -->
                                    <div v-if="crowdfundingLoanType == null && UserPersonType == 1">
                                        <p class="bold black mb-4 mt-2">¿Con qué régimen fiscal solicitarás tu Crédito?</p>
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-1/3 md:w-3/12 lg:w-3/12 mb-8 mt-2"
                                                v-for="(item, index) in ecoPersonTypesOptions" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="termsOptions" :label="item.label"
                                                    :value="item.value" v-model="crowdfundingLoanTypeValue" />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!projectDetailsCompleted">
                                        <p v-if="isSFV" class="bold black mb-4">¿Cuál es el monto aproximado del costo de tu sistema o producto?</p>
                                        <p v-if="!isSFV" class="bold black mb-4">¿Cuánto dinero necesitas para tu vehículo?</p>
                                        <div class="vx-row mb-4">
                                            <!-- <div class="vx-col w-1/3 mt-6 custom-range">
                                                <aesth-vue-range-input 
                                                    class="custom-range"
                                                    v-model.number="productValue"
                                                    step="10000"
                                                    :max="isSFV ? maxAmount : 1000000" />
                                                    <div class="range-tags">
                                                        <span>$1 MXN </span>
                                                        <span class="rigth">{{ mxnFormat(isSFV ? maxAmount : 1000000) }}</span>
                                                    </div>
                                            </div> -->
                                            <!-- <div class="vx-col w-1/3">
                                                <div class="w-1/2">
                                                    <vs-input
                                                        class="w-full"
                                                        label="Monto"
                                                        :disabled="true"
                                                        name="productMoney"
                                                        v-validate="requiredRules"
                                                        :value="productMoney"
                                                        :danger="hasError('productMoney')"
                                                        :danger-text="errorText('productMoney')"
                                                        :success="isSuccess('productMoney')"
                                                        icon-pack="feather"
                                                    />
                                                </div>
                                            </div> -->
                                            <div class="vx-col sm:w-full md:w-1/3 lg:w-2/5">
                                                <div class="w-1/2">
                                                    <vs-input
                                                        @input="(val) => (productValue = `${liveCostFormatNoCents(productValue)}`)"
                                                        class="w-full"
                                                        label="Monto"
                                                        name="productValue"
                                                        v-validate="requiredRules"
                                                        v-model.lazy="productValue"
                                                        :min="calculateMinAmount"
                                                        :max="calculateMaxAmount"
                                                        autocomplete="off"
                                                        :danger="hasError('productValue')"
                                                        :danger-text="errorText('productValue')"
                                                        :success="isSuccess('productValue')"
                                                        icon-pack="feather"
                                                        icon="icon-dollar-sign"
                                                    />
                                                    <small>Ingresa desde {{ moneyFormat(calculateMinAmount) }} hasta {{ moneyFormat(calculateMaxAmount) }}</small>
                                                </div>
                                            </div>
                                        </div>

                                        <p v-if="isSFV" @click.stop="showCFE = true" class="bold clickable-img not-suere-btn">
                                            No estoy seguro
                                        </p>

                                        <div v-if="showCFE" class="mt-base">
                                            <vs-divider></vs-divider>

                                            <p class="mb-base mt-base">
                                                ¡No te preocupes! <br>
                                                Te ayudamos a estimar el costo del proyecto a partir de tu consumo de electricidad anual.
                                            </p>

                                            <p class="bold black mb-4">¿Aproximadamente cuánta electricidad consumes al año en kWh?</p>
                                            <div class="vx-row mb-2">
                                                <!-- <div class="vx-col w-1/3 mt-6 custom-range">
                                                    <aesth-vue-range-input 
                                                        class="custom-range"
                                                        v-model.number="cfeValue"
                                                        step="10000"
                                                        :max="300000" />
                                                        <div class="range-tags">
                                                            <span>$1 MXN </span>
                                                            <span class="rigth">$300,000 MXN</span>
                                                        </div>
                                                </div> -->
                                                <div class="vx-col w-1/3">
                                                    <div class="w-1/2">
                                                        <vs-input
                                                            class="w-full"
                                                            label="Consumo anual en kWh"
                                                            name="cfeValue"
                                                            v-model="cfeValue"
                                                            :danger="hasError('cfeValue')"
                                                            :danger-text="errorText('cfeValue')"
                                                            :success="isSuccess('cfeValue')"
                                                            icon-pack="feather"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p @click.stop="showCFEPrompt = true" class="bold clickable-img not-suere-btn mb-4">
                                                ¿Dónde encuentro mi consumo anual?
                                            </p>
                                            <p class="ml-1 text-md">
                                                <span class="bold">Nota</span> 
                                                <br> Calculamos el monto del proyecto, lo verás en el campo de arriba.
                                                <br> Precio estimado de equipos e instalación.
                                                <br> El monto real te lo proporcionará el proveedor de paneles.

                                            </p>
                                        </div>

                                        <!-- <template v-else>
                                            <p class="bold black mb-4">¿Dónde se instalará tu proyecto?</p>
                                            <div class="vx-row mb-8">
                                                <div class="vx-col sm:w-1/3 lg:w-2/12 mb-8 mt-2"
                                                    v-for="(item, index) in locationOptions" :key="index">
                                                    <rg-radio
                                                        class="w-full" groupName="locationOptions" :label="item.label"
                                                        :value="item.value" v-model="locationOption" />
                                                </div>
                                            </div>
        
                                            
                                            
                                        </template> -->


                                        <div class="vx-row justify-end mt-12">
                                            <!-- <div class="vx-col">
                                                <vs-button @click.stop="prevStep('4_set_supplier_information')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div> -->
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('6_set_project_values')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="bold black mb-4 mt-2">¿A qué plazo deseas pagar tu crédito?</p>
                                        <div v-if="!isSFV" class="vx-row mb-8">
                                            <div class="vx-col sm:w-1/3 md:w-2/12 lg:w-1/12 mb-8 mt-2"
                                                v-for="(item, index) in currentTerm" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="termsOptions" :label="`${item} ${item > 1 ? 'años' : 'año'}`"
                                                    :value="item" v-model="loanTerm" />
                                            </div>
                                        </div>
                                        <div v-else class="vx-row mb-8">
                                            <!-- <div class="vx-col sm:w-1/3 md:w-2/12 lg:w-1/12 mb-8 mt-2"
                                                v-for="(item, index) in sfvTermsOptions" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="sfvTermsOptions" :label="item.label"
                                                    :value="item.value" v-model="loanTerm" />
                                            </div> -->
                                            <div class="vx-col sm:w-full md:w-1/3 mt-2 custom-range">
                                                <h4>{{ loanTerm }} año(s)</h4>
                                                <aesth-vue-range-input 
                                                    class="custom-range"
                                                    v-model.number="loanTerm"
                                                    step="1"
                                                    :min="currentTerm[0]"
                                                    :max="currentTerm[currentTerm.length-1]" />
                                                    <div class="range-tags">
                                                        <small>{{ currentTerm[0] }} año </small>
                                                        <small class="rigth">{{ currentTerm[currentTerm.length-1] }} años</small>
                                                    </div>
                                            </div>
                                        </div>
                                        
                                        <p class="bold black mb-4 mt-base">¿Deseas hacer un pago inicial o enganche?</p>
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-1/2 lg:w-2/12 mb-8 mt-2"
                                                v-for="(item, index) in needsInitialPayment" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="needsInitialPayment" :label="item.label"
                                                    :value="item.value" v-model="hasInitialPayment" />
                                            </div>
                                        </div>
                                        <!-- Sí necesita enganche -->
                                        <div v-if="hasInitialPayment == 1" class="vx-row border-left ml-2">
                                            <div class="vx-col w-full">
                                                <p class="bold">
                                                    ¿Cuánto deseas aportar de enganche?
                                                </p>
                                                <small>*El mínimo de enganche es 5% y el máximo es 70%</small>
                                            </div>
                                            <div class="vx-col sm:w-1/2 md:w-2/12 lg:w-1/12 mb-4 mt-2" v-for="(item, index) in initialPaymentOptions" :key="index">
                                                <rg-radio
                                                    class="w-full" groupName="initialPaymentOptions" :label="item.label"
                                                    :value="item.value" v-model="initialPaymentOption" />
                                            </div>
                                            <div class="vx-col w-full">
                                                <div class="vx-row">
                                                    <div v-show="initialPaymentOption == 1" class="vx-col w-3/6">
                                                        <vs-input
                                                            class="w-full"
                                                            label="Monto"
                                                            name="initialPaymentValue"
                                                            v-money="money"
                                                            v-validate="requiredRules"
                                                            v-model.lazy="initialPaymentValue"
                                                            :danger="hasError('initialPaymentValue')"
                                                            :danger-text="errorText('initialPaymentValue')"
                                                            :success="isSuccess('initialPaymentValue')"
                                                            icon-pack="feather"
                                                            icon="icon-dollar-sign"
                                                        />
                                                    </div>
                                                    <div v-show="initialPaymentOption == 2" class="vx-col w-2/6">
                                                        <vs-input
                                                            class="w-full"
                                                            label="Porcentaje"
                                                            name="initialPaymentPercentage"
                                                            number
                                                            min="5"
                                                            max="70"
                                                            v-validate="requiredRules"
                                                            v-model="initialPaymentPercentage"
                                                            :danger="hasError('initialPaymentPercentage')"
                                                            :danger-text="errorText('initialPaymentPercentage')"
                                                            :success="isSuccess('initialPaymentPercentage')"
                                                            icon-pack="feather"
                                                            icon="icon-percent"
                                                            :placeholder="requiredPlaceholder"
                                                        />
                                                        <small>Monto {{ moneyFormat(percentValueCalculated) }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('6_set_project_values')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button :disabled="loanIdCreated != null" @click.stop="nextStep('7_set_finance_data')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- AQUÍ DEBE IR UN ELSE QUE HAGA REFERENCIA A LOS CRÉDITOS QUE NO SON DE PRODUCTO. -->
                            <div v-if="(isPersonalLoan || isPymeLoan) && initialInfoCompleted && !moneyLoanCompleted">
                                <h4 class="mb-2">Datos del crédito</h4>
                                <p>Ingresa el monto y plazo que deseas.</p>
                                <vs-divider></vs-divider>
                                <p class="bold black mb-4 mt-2">¿Cuánto dinero necesitas?</p>
                                <div class="vx-row mb-8">
                                    <!-- {{ loanAmount }} -->
                                    <!-- <div class="vx-col w-1/3 mt-6">
                                        <div class="centerx custom-range ">
                                            <aesth-vue-range-input 
                                                class="custom-range"
                                                v-model.number="loanAmount"
                                                step="10000"
                                                :max="maxAmount" />
                                            <div class="range-tags">
                                                <span>$1 MXN </span>
                                                <span class="rigth">{{ mxnFormat(maxAmount) }}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="vx-col sm:w-full md:w-1/3 lg:w-2/5">
                                        <div class="w-1/2">
                                            <vs-input
                                                class="w-full"
                                                label="Monto"
                                                name="loanAmount"
                                                v-validate="requiredRules"
                                                v-model.lazy="loanAmount"
                                                v-money="money"
                                                :min="calculateMinAmount"
                                                :max="calculateMaxAmount"
                                                autocomplete="off"
                                                :danger="hasError('loanAmount')"
                                                :danger-text="errorText('loanAmount')"
                                                :success="isSuccess('loanAmount')"
                                                icon-pack="feather"
                                                icon="icon-dollar-sign"
                                            />
                                            <small>Ingresa desde {{ moneyFormat(calculateMinAmount) }} hasta {{ moneyFormat(calculateMaxAmount) }}</small>
                                        </div>
                                    </div>
                                </div>

                                <p class="bold black mb-4 mt-2">¿A qué plazo deseas pagar tu crédito?</p>
                                <div class="vx-row">
                                    <div class="vx-col w-2/6 mb-8 mt-2"
                                        v-for="(item, index) in currentTerm" :key="index">
                                        <rg-radio
                                            class="w-full" groupName="termsLimitedOptions" :label="`${item} ${item > 1 ? 'años' : 'año'}`"
                                            :value="item" v-model="limitedTerms" />
                                    </div>
                                </div>
                                
                                <div class="vx-row justify-end mt-12">
                                    <!-- <div class="vx-col">
                                        <vs-button @click.stop="prevStep('2_set_person_type')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                    </div> -->
                                    <div class="vx-col">
                                        <vs-button @click.stop="nextStep('8_set_money_loan_info')" color="dark">Continuar</vs-button>
                                    </div>
                                </div>
                            </div>
                            <!-- PREGUNTAS SOBRE EL DESTINO DE LOS RECURSOS PARA PFAE Y PM -->
                            <div v-if="needsToShowImproves">
                                <template v-if="isPFAEAndPersonal">
                                    <!-- ¿en qué planeas usarlo? -->
                                    <div v-if="(isPersonalLoan) && initialInfoCompleted && moneyLoanCompleted && !loanUseReasonCompleted">
                                        <h4 class="mb-2">Sobre tu crédito</h4>
                                        <p>Platícanos sobre el destino de los recursos.</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4 mt-2">¿En qué planeas usarlo?</p>
                                        <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="loan_use_reason"/>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('8_set_money_loan_info')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('9_improve_business_desc')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMO MEJORAR TU NEGOCIO PM Y PFAE -->
                                    <div v-if="(isPersonalLoan) && initialInfoCompleted && moneyLoanCompleted && loanUseReasonCompleted">
                                        <h4 class="mb-2">Sobre tu crédito</h4>
                                        <p>Platícanos sobre el destino de los recursos.</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4 mt-2">Platícanos cómo mejorarías tu vida o la de tus familiares con este crédito.</p>
                                        <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="improve_social"/>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('9_improve_business_desc')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button :disabled="loanIdCreated != null" @click.stop="nextStep('10_improve_social_desc')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <!-- ¿en qué planeas usarlo? -->
                                    <div v-if="(isPersonalLoan || isPymeLoan) && initialInfoCompleted && moneyLoanCompleted && !loanUseReasonCompleted">
                                        <h4 class="mb-2">Sobre tu crédito</h4>
                                        <p>Platícanos sobre el destino de los recursos.</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4 mt-2">¿En qué planeas usarlo?</p>
                                        <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="loan_use_reason"/>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('8_set_money_loan_info')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('9_loan_use_reason_desc')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMO MEJORAR TU NEGOCIO PM Y PFAE -->
                                    <div v-if="(isPersonalLoan || isPymeLoan) && initialInfoCompleted && moneyLoanCompleted && loanUseReasonCompleted && !businessImproveCompleted">
                                        <h4 class="mb-2">Sobre tu crédito</h4>
                                        <p>Platícanos sobre el destino de los recursos.</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4 mt-2">Platícanos como mejoraría tu negocio con este crédito.</p>
                                        <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="improve_business"/>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('9_loan_use_reason_desc')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('9_improve_business_desc')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMO MEJORAR TU NEGOCIO PM Y PFAE -->
                                    <div v-if="(isPersonalLoan || isPymeLoan) && initialInfoCompleted && moneyLoanCompleted && loanUseReasonCompleted && businessImproveCompleted">
                                        <h4 class="mb-2">Sobre tu crédito</h4>
                                        <p>Platícanos sobre el destino de los recursos.</p>
                                        <vs-divider></vs-divider>
                                        <p class="bold black mb-4 mt-2">Platícanos como a través de tus operaciones o proyecto generas beneficios ambientales y/o sociales.</p>
                                        <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="improve_social"/>
                                        <div class="vx-row justify-end mt-12">
                                            <div class="vx-col">
                                                <vs-button @click.stop="prevStep('9_improve_business_desc')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                            </div>
                                            <div class="vx-col">
                                                <vs-button @click.stop="nextStep('10_improve_social_desc')" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <!-- PREGUNTAS SOBRE EL DESTINO DE LOS RECURSOS PARA PF -->
                            <div v-if="needsToShowImprovesPF">
                                <!-- ¿en qué planeas usarlo? -->
                                <div v-if="(isPersonalLoan) && initialInfoCompleted && moneyLoanCompleted && !loanUseReasonCompleted">
                                    <h4 class="mb-2">Sobre tu crédito</h4>
                                    <p>Platícanos sobre el destino de los recursos.</p>
                                    <vs-divider></vs-divider>
                                    <p class="bold black mb-4 mt-2">¿En qué planeas usarlo?</p>
                                    <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="loan_use_reason"/>
                                    <div class="vx-row justify-end mt-12">
                                        <div class="vx-col">
                                            <vs-button @click.stop="prevStep('8_set_money_loan_info')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                        </div>
                                        <div class="vx-col">
                                            <vs-button @click.stop="nextStep('9_improve_business_desc')" color="dark">Continuar</vs-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- COMO MEJORAR TU NEGOCIO PM Y PFAE -->
                                <div v-if="(isPersonalLoan) && initialInfoCompleted && moneyLoanCompleted && loanUseReasonCompleted">
                                    <h4 class="mb-2">Sobre tu crédito</h4>
                                    <p>Platícanos sobre el destino de los recursos.</p>
                                    <vs-divider></vs-divider>
                                    <p class="bold black mb-4 mt-2">Platícanos cómo mejorarías tu vida o la de tus familiares con este crédito.</p>
                                    <vs-textarea class="sm:w-full md:w-1/3" maxlength="255" v-model="improve_social"/>
                                    <div class="vx-row justify-end mt-12">
                                        <div class="vx-col">
                                            <vs-button @click.stop="prevStep('9_improve_business_desc')" color="dark" type="bordered" class="black">Atrás</vs-button>
                                        </div>
                                        <div class="vx-col">
                                            <vs-button :disabled="loanIdCreated != null" @click.stop="nextStep('10_improve_social_desc')" color="dark">Continuar</vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <!-- POPUP DETALLE CFE -->
        <vs-popup title="" :active.sync="showCFEPrompt">
            <h3 class="mb-4">
                Calcula tu consumo anual de CFE.
            </h3>

            <p class="black text-lg mb-4">
                Puedes calcular tu consumo anual usando la información que aparece <b>al reverso de tu recibo de CFE en la parte superior.</b>
                <br>
                <br>
                Suma los montos que aparecen en la segunda columna, <b>kWh</b>, de los últimos 12 meses e ingresa esa cantidad en tu solicitud
            </p>
            <div class="mb-6 mt-10 text-center">
                <img :src="cfeMockup" class="cursor-pointer text-accent align-self-felx-start" />
            </div>
        </vs-popup>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import {VMoney} from 'v-money';
import currencyHelper from '@mixins/currencyHelper';
import formatHelper from '@mixins/formatHelper';
import AesthVueRangeInput from "aesth-vue-range-input";
import LoanTable from "./components/LoanTable.vue";

const supplierId = 2323;
const initialPaymentRange = [5, 70];

const vehicleMaxAmount = 1500000;

// CFE
const daysInYear = 365;
const irradiation = 5;
const efficiency = .9;
const pricePerWatDlls = 1.07;
const tax = 1.16;
const MOVILIDAD_ID = 7;

const loanTypes = [
    { label: "Ecotecnologías", value: "1", desc: "Equipos que reducen tu huella de carbono." },
    { label: "Personal", value: "2", desc: "Recursos oportunos para mejorar tu vida." },
    { label: "PYME", value: "3", desc: "Capital de trabajo para crecer tu negocio." },
];
const personTypes = [
    { label: "Persona física", value: "1", desc: "Hasta $350,000 MXN, abierto a cualquier mexicano." },
    { label: "Persona física con actividad empresaria", value: "2", desc: "Hasta $5,000,000 MXN, se requiere tener dicho regimen fiscal." },
    { label: "Persona moral", value: "3", desc: "Hasta $10,000,000 MXN, se acredita a alguna sociedad." },
];
const hasSupplierOptions = [
    { label: "Sí, tengo uno en específico", value: "1", desc: null },
    { label: "No, quiero que RedGirasol me asigne uno", value: "2", desc: null },
];
const wantsToContinueWithSupplierOptions = [
    { label: "Sí, continuar con este promotor", value: "1", desc: null },
    { label: "No, quiero otro promotor.", value: "2", desc: null },
];
const termsOptions = [
    { label: "1 año", value: "12", desc: null },
    { label: "2 años", value: "24", desc: null },
    { label: "3 años", value: "36", desc: null },
    { label: "4 años", value: "48", desc: null },
    { label: "5 años", value: "60", desc: null },
];
const sfvTermsOptions = [
    { label: "1 año", value: "12", desc: null },
    { label: "2 años", value: "24", desc: null },
    { label: "4 años", value: "48", desc: null },
    { label: "6 años", value: "72", desc: null },
    { label: "8 años", value: "96", desc: null },
    { label: "10 años", value: "120", desc: null },
];

const termsLimitedOptions = [
    { label: "1 año", value: "12", desc: null },
    { label: "2 años", value: "24", desc: null },
    { label: "3 años", value: "36", desc: null },
    { label: "4 años", value: "48", desc: null },
    { label: "5 años", value: "60", desc: null },
];
const needsInitialPayment = [
    { label: "Sí, continuar con enganche", value: "1", desc: null },
    { label: "No, no voy a hacer un enganche.", value: "2", desc: null },
];
const initialPaymentOptions = [
    { label: "Monto ($)", value: "1", desc: null },
    { label: "Porcentaje (%)", value: "2", desc: null },
];

const locationOptions = [
    { label: "Casa", value: "1", desc: null },
    { label: "Negocio", value: "2", desc: null },
];

const ecoPersonTypesOptions = [
    { label: "Persona Física", value: "1", desc: null },
    { label: "Persona Física con Actividad Empresarial", value: "2", desc: null },
]

export default {
    name: 'LoanRequestPage',
    mixins: [inputHelper, currencyHelper, formatHelper],
    directives: {money: VMoney},
    components: {
        AesthVueRangeInput,
        LoanTable
    },
    data: () => ({
        cfeMockup: require("@assets/images/loan-onboarding/cfe_mockup.png"),
        loanIdCreated: null,
        loanTypes: loanTypes,
        personTypes: personTypes,
        hasSupplierOptions: hasSupplierOptions,
        wantsToContinueWithSupplierOptions: wantsToContinueWithSupplierOptions,
        termsOptions: termsOptions,
        sfvTermsOptions: sfvTermsOptions,
        needsInitialPayment: needsInitialPayment,
        initialPaymentOptions: initialPaymentOptions,
        termsLimitedOptions: termsLimitedOptions,
        locationOptions: locationOptions,
        ecoPersonTypesOptions: ecoPersonTypesOptions,
        crowdfundingLoanTypeValue: null,
        loanType: null,
        personType: null,
        hasSupplier: null,
        familyGroupSelected: null,
        continueWithReferalSupplier: null,
        cfeValue: null,
        cfeMoney: 0,
        productValue: 0,
        productMoney: 0,
        supplierName: null,
        supplierPhone: null,
        country_calling_code: null,
        loanTerm: null,
        hasInitialPayment: null,
        initialPaymentOption: 1,
        initialPaymentValue: null,
        initialPaymentPercentage: null,
        loan_use_reason: "",
        improve_business: "",
        improve_social: "",

        loanAmount: null,
        loanAmountMoney: null,
        limitedTerms: null,
        maxAmount: 300000,
        vehicleMaxAmount: vehicleMaxAmount,
        isMounted: false, 
        showCFE: false,
        showCFEPrompt: false,
        locationOption: null,

        requiredPlaceholder: "(Requerido)",
        requiredRules: "required",
        colClass: "vx-col lg:w-1/4 sm:w-full w-full mb-5",
        step: 1,
        onboardingStep: {
            "1_loan_type": null,
            "2_person_type": null,
            "3_family_group": null,
            "4_supplier_information": {
                "supplier_name": null,
                "supplier_phone": null,
                "supplier_id": null,
            },
            "6_project_details": {
                "cfe_value": null,
                "product_cost": null,
                "location_value": null
            },
            "7_finance_details": {
                "loan_term": null,
                "initial_amount": null,
                "initial_percent": null,
            },
            "8_money_loan_info": {
                "loan_amount": null,
                "loan_term": null,
            },
            "9_destination_of_resources": {
                "improve_business": null,
                "improve_social": null,
                "loan_use_reason": null,
            },
            "10_crowdfunding_loan_type": null
        },
        slas: {
            min_financed_amount_mxn: null,
            max_financed_amount_mxn: null,
            max_applicant_pf_financed_amount_udis: null,
            max_first_project_supplier_total_cost_mxn: null,
            max_applicant_pm_financed_amount_udis: null,
            min_financed_amount_udis: null,
            max_financed_amount_mxn: null
        },
        collections: {
            family_groups: [],
        },
        money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false
        },
        udis: null,
        exchange_mxn_usd: null,
        projects: [],
        hasEcoTechnologies: false,
        hasPYME: false,
        hasPersonal: false,
        showLoanUnavailable: false,
        percentValueCalculated: 0,
        termOptions: null
        
    }),
    props: {
        loanTypeProp: String|Number
    },
    watch: {
        // loanAmount: function(val) {
        //     // console.log(val)
        //     this.loanAmount = this.costFormat(val);
        //     // console.log(this.loanAmountMoney);
        // },
        cfeValue: function(val) {
            this.calculateLoanAmountByCFEValue();
        },
        // productValue: function(val) {
        //     // console.log(val)
        //     this.productMoney = this.costFormat(val);
        //     // console.log(this.loanAmountMoney);
        // },
        initialPaymentPercentage: function(val) {
            this.percentValueCalculated = (this.unMoneyFormat(this.productValue) * val) / 100;
        },
    },
    beforeDestroy() { this.$validator.pause() }, // Desactiva las validaciones
    async mounted() {
        this.showLoading(true);
        this.isMounted = false;
        await this.getLoanTerms();
        await this.getCollections();
        this.setPersonType();
        await this.getLoans();
        this.showLoading(false);
        this.continueWithReferalSupplier = this.hasSupplierId ? 1 : 0;
        if(this.isSFV) {
            this.loanTerm = 1;
        }

        if(this.isMoral) {
            this.crowdfundingLoanType = 2;
            this.crowdfundingLoanTypeValue = 2;
            this.onboardingStep["10_crowdfunding_loan_type"] = 2;
        }

        this.isMounted = true;
    },
    async created() {
        if(!this.showLoanUnavailable) {
            await this.setLoanData();
        }
    },
    computed: {
        isPF() {
            return this.UserPersonType == 1  && this.ApplicantTaxProfile == 0;
        },
        isPFAE() {
            return this.UserPersonType == 1 && this.ApplicantTaxProfile == 1;
        },
        isMoral() {
            return this.UserPersonType == 0 || this.UserPersonType == 3;
        },
        isEcotechnologiesLoan() {
            return this.loanType == "1";
        },
        isPersonalLoan() {
            return this.loanType == "2";
        },
        isPymeLoan() {
            return this.loanType == "3";
        },
        apiPostRoute() {
            let route = null;
            if (this.isEcotechnologiesLoan) {
                route = "eco";
            } else if(this.isPersonalLoan) {
                route = "personal";
            } else if(this.isPymeLoan) {
                route = "pyme";
            }

            return route;
        },
        needsToShowPersonalTypeForm() {
            return !this.isPersonalLoan;
        },
        pfIsDisabled(){
            return this.isPymeLoan || this.isMoral;
        },
        initialInfoCompleted() {
            return this.onboardingStep["1_loan_type"] != null && this.onboardingStep["2_person_type"] != null;
        },
        getTechnologiesFormat() {
            let technologies = [];
            this.collections.family_groups.forEach(familyGroup => {
                if(familyGroup.id == 1 || familyGroup.id == MOVILIDAD_ID) {
                    technologies.push({
                        label: familyGroup.name,
                        value: familyGroup.id,
                        desc: familyGroup.description,
                    });
                }
            });
            return technologies;
        },
        familyGroupCompleted() {
            return this.onboardingStep["3_family_group"] != null;
        },
        hasSupplierId() {
            return (
                this.ApplicantSupplierReferred != null
                && (this.ApplicantSupplierReferred.restriction_level != "high" && this.ApplicantSupplierReferred.restriction_level != "banned")
            );
        },
        ApplicantSupplierReferredData() {
            return this.ApplicantSupplierReferred;
        },
        supplierInformationCompleted() {
            if(this.hasSupplier == 1) {
                return this.onboardingStep["4_supplier_information"].supplier_name != null && this.onboardingStep["4_supplier_information"].supplier_phone != null;
            } else {
                return this.onboardingStep["4_supplier_information"].supplier_id != null;
            }
        },
        projectDetailsCompleted() {
            if(this.showCFE) {
                return this.onboardingStep["6_project_details"].product_cost != null;
            } else {
                return this.onboardingStep["6_project_details"].product_cost != null;
            }
        },
        projectFinanceDetailsCompleted() {
            return this.onboardingStep["7_finance_details"].loan_term != null;
        },
        getTotalSteps() {
            let steps = 0;
            if (this.isEcotechnologiesLoan) {
                if(this.isSFV) {
                    steps = 6;
                } else {
                    steps = 4;
                }
            } else if(this.isPersonalLoan) {
                steps = 4;
            } else if(this.isPymeLoan) {
                steps = 5;
            }

            return steps;
        },
        getLoanTypeNamed() {
            let name = "";
            if (this.isEcotechnologiesLoan) {
                if(this.isSFV) {
                    name = "Solar";
                } else {
                    name = "Automotriz"
                }
            } else if(this.isPersonalLoan) {
                name = "Personal";
            } else if(this.isPymeLoan) {
                name = "PYME";
            }

            return name;
        },
        calculatePercent() {
            if(this.getTotalSteps == 0) {
                return 0;
            }
            return (this.step * 100) / this.getTotalSteps;
        },
        calculateMaxAmount() {
            if(this.isPymeLoan || this.crowdfundingLoanTypeValue == 2) {
                return this.LoanLimits.pm_max_limit;
            }
            if(this.isPFAE && this.isPersonalLoan) {
                return this.slas.max_applicant_pf_financed_amount_udis * this.udis;
            }
            return this.LoanLimits.max_limit;
        },
        calculateMinAmount() {
            return this.LoanLimits.min_limit;
        },
        moneyLoanCompleted() {
            return this.onboardingStep["8_money_loan_info"].loan_amount != null && this.onboardingStep["8_money_loan_info"].loan_term != null;
        }, 
        businessImproveCompleted() {
            return this.onboardingStep["9_destination_of_resources"].improve_business != null && this.onboardingStep["9_destination_of_resources"].improve_business != "";
        },
        loanUseReasonCompleted() {
            return this.onboardingStep["9_destination_of_resources"].loan_use_reason != null && this.onboardingStep["9_destination_of_resources"].loan_use_reason != "";
        },
        needsToShowImproves() {
            return this.isPFAE || this.isMoral || (this.isPF && this.isPymeLoan);
        },
        needsToShowImprovesPF() {
            return this.isPF;
        },
        isPFAEAndPersonal() {
            return this.isPFAE && this.isPersonalLoan;
        },
        isSFV() {
            return this.onboardingStep["3_family_group"] == 1;
        },
        currentInitialPercentMoneyValue() {
            return this.initialPaymentPercentage * this.loanAmount;
        },
        crowdfundingLoanType() {
            return this.onboardingStep["10_crowdfunding_loan_type"];
        },
        currentLoanType() {
            let loanType = 0;
            if (this.isEcotechnologiesLoan) {
                if(this.isSFV) {
                    loanType = 1; // SOLAR
                } else {
                    loanType = 6; // AUTOMOTRIZ
                }
            } else if(this.isPersonalLoan) {
                loanType = 5; // PERSONAL
            } else if(this.isPymeLoan) {
                loanType = 4; // PYME
            }

            return loanType;
        },
        currentFinanceProductId() {
            let product = 5;
            if (this.currentLoanType == 1) {
                product = 5;
            } else if (this.currentLoanType == 4) {
                product = 6;
            } else if (this.currentLoanType == 5) {
                product = 7;
            } else if (this.currentLoanType == 6) {
                product = 8;
            }

            return product;
        },
        currentTerm() {
            let terms = [];
            console.log(this.termOptions);
            const current = this.termOptions.find(x => x.loan_type_id == this.currentLoanType || x.financial_product_id == this.currentFinanceProductId);

            if (current) {
                terms = current.terms.map(function(x) {

                    let yearTerm = x/12;
                    if(Number.isInteger(yearTerm)) {
                        return yearTerm;
                    } 
                    
                });
            }

            let filtered = terms.filter(function (el) {
                return el != null;
            });

            return filtered;
        },
    },
    methods: {
        async getLoanTerms() {
            const res = await axios.get(`api/v1/forms/getLoanTerms`);
            this.termOptions = res.data;
        },
        setLoanData() {
            // Setea información dependiento del tipo que venga desde url
            if(this.loanTypeProp == 1) { // SFV
                this.onboardingStep["1_loan_type"] = "1";
                this.loanType = "1";
                this.onboardingStep["3_family_group"] = "1";
            } else if(this.loanTypeProp == 2) { // MOVILIDAD
                this.loanType = "1";
                this.onboardingStep["1_loan_type"] = "1";
                this.onboardingStep["3_family_group"] = MOVILIDAD_ID;
                this.onboardingStep["4_supplier_information"].supplier_id = supplierId;
            } else if(this.loanTypeProp == 4) { // CRÉDITO PARA PERSONAL PARA PFAE
                this.onboardingStep["1_loan_type"] = 2;
                this.loanType = 2;
            } else if(this.loanTypeProp == 3) { // PERSONAL / PYME
                this.onboardingStep["1_loan_type"] = 3;
                this.loanType = 3;
            }
        },
        async getLoans() {
            try {
                const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
                this.projects = res.data.data;
                let loanCounter = 0;

                this.projects.forEach(project => {
                    let loanType = project.loan_type;
                    let loanIsFinished = project.status_int >= 8;
                    if(!loanIsFinished) {
                        if(loanType == 1) {
                            this.hasEcoTechnologies = true;
                            loanCounter++;
                        } else if(loanType == 5) {
                            this.hasPersonal = true;
                            loanCounter++;
                        } else if(loanType == 4) {
                            this.hasPYME = true;
                            loanCounter++;
                        }
                    }

                });
                this.showLoanUnavailable = (loanCounter >= 2);
            } catch (error) {
                console.log(error);
                this.failedOperationNotif("Algo salió mal.");
            }
        },
        setPersonType() {
            if(this.isPF) {
                this.personType = "1";
            } else if(this.isPFAE) {
                this.personType = "2";
            } else if(this.isMoral) {
                this.personType = "3";
            }
            this.onboardingStep["2_person_type"] = this.personType;
        },
        async nextStep(step) {
            switch(step) {
                case '1_set_loan_type':
                    if (this.loanType != null) {
                        this.onboardingStep["1_loan_type"] = this.loanType;
                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Tipo de crédito',
                            text: 'Para continuar debes seleccionar crédito deseas solicitar.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    break;
                case '2_set_person_type':
                    if (this.personType != null) {
                        this.onboardingStep["2_person_type"] = this.personType;
                        // Sí es prestamo personal, se salta el paso de tipo de persona
                        // if(this.isPF) {
                        //     this.onboardingStep["1_loan_type"] = loanId;
                        //     this.loanType = loanId;
                        //     // this.step++;
                        // }
                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Tipo de crédito',
                            text: 'Para continuar debes seleccionar cómo deseas solicitar el crédito.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    break;
                case '3_set_family_group':
                    if (this.familyGroupSelected != null) {
                        this.onboardingStep["3_family_group"] = this.familyGroupSelected;

                        // Sí es movilidad, no se ocupa proveedor y se setea en RG.
                        if(this.familyGroupSelected == MOVILIDAD_ID) {
                            this.onboardingStep["4_supplier_information"].supplier_id = supplierId;
                        }

                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Tipo de producto',
                            text: 'Para continuar debes seleccionar el producto o eco-tecnología para tu crédito.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    break;
                case '4_set_supplier_information':
                    if(this.hasSupplier != null) {
                        if(this.hasSupplier == 1) {
                            const isValid = await this.validateForm(); 
                            if (isValid) {
                                this.onboardingStep["4_supplier_information"].supplier_name = this.supplierName;
                                this.onboardingStep["4_supplier_information"].supplier_phone = this.country_calling_code + this.supplierPhone;
                                this.step++;
                            } else {
                                this.$vs.notify({
                                    title: 'Información de tu promotor',
                                    text: 'Para continuar debes compartirnos la información de contacto de tu promotor.',
                                    color: 'warning',
                                    position: 'top-center'
                                });
                                return;
                            }
                        } else {
                            this.onboardingStep["4_supplier_information"].supplier_id = supplierId;
                            this.step++;
                        }
                    } else {
                        this.$vs.notify({
                            title: 'Información de tu promotor',
                            text: 'Compartenos como deseas continuar con tu proyecto.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    break;
                case '5_set_supplier_referal':
                    if(this.continueWithReferalSupplier != null) {
                        // si quiere continuar con el promotor referido
                        if(this.continueWithReferalSupplier == 1) {
                            this.onboardingStep["4_supplier_information"].supplier_id = this.ApplicantSupplierReferredData.id;
                        } else {
                            // si no, le asigno a RG.
                            this.onboardingStep["4_supplier_information"].supplier_id = null;
                            this.continueWithReferalSupplier = 0;
                            this.$forceUpdate();
                        }
                        this.step++;
                    } else {
                            this.$vs.notify({
                                title: 'Información de tu promotor',
                                text: 'Para continuar debes confirmar si deseas realizar este proyecto con el promotor que te refirió.',
                                color: 'warning',
                                position: 'top-center'
                            });
                            return;
                        }
                    break;
                case '6_set_project_values':

                    // Sí es un crédito de movilidad o sfv, se necesita el crowdfunding_loan_type_value
                    if(this.isEcotechnologiesLoan) {
                        if(this.crowdfundingLoanTypeValue == null) {
                            this.$vs.notify({
                                title: 'Información adicional',
                                text: 'Para continuar debes compartirnos el régimen fiscal con el que deseas solicitar tu crédito.',
                                color: 'warning',
                                position: 'top-center'
                            });
                            return;
                        } else {
                        }
                    }

                    const productValue = this.unMoneyFormat(this.productValue);
                    if(productValue < this.calculateMinAmount || productValue > this.calculateMaxAmount) {
                        this.$vs.notify({
                            title: 'Monto del crédito',
                            text: 'El monto solicitado debe estar dentro del rango de financiamiento.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }

                    if(productValue > 0) {
                        // this.onboardingStep["6_project_details"].cfe_value = cfeMoney;
                        this.onboardingStep["6_project_details"].product_cost = productValue;
                        this.onboardingStep["10_crowdfunding_loan_type"] = this.crowdfundingLoanTypeValue;
                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Información adicional',
                            text: 'Para continuar debes compartirnos el costo aproximado de tu proyecto.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }

                    break;
                case '7_set_finance_data':
                    if(this.loanTerm != null) {
                        if (this.hasInitialPayment == 1) {
                            let formValid = false;

                            if(this.initialPaymentOption == 1) { // MONTO
                                let initialPaymentValue = this.unMoneyFormat(this.initialPaymentValue);
                                if(initialPaymentValue > 0) {
                                    let percent = (initialPaymentValue * 100) / this.unMoneyFormat(this.productValue);
                                    formValid = (percent >= initialPaymentRange[0] && percent <= initialPaymentRange[1]);
                                }
                            } else { // ENGANCHE
                                formValid = (this.initialPaymentPercentage >= initialPaymentRange[0] && this.initialPaymentPercentage <= initialPaymentRange[1]);
                            }

                            if(formValid) {
                                this.onboardingStep["7_finance_details"].initial_amount = this.unMoneyFormat(this.initialPaymentValue);
                                this.onboardingStep["7_finance_details"].initial_percent = this.initialPaymentPercentage;
                                this.step++;
                            } else {
                                this.$vs.notify({
                                    title: 'Información adicional',
                                    text: 'Valida por favor el valor del enganche, el mínimo permitido es del 5%, mientras que el máximo es de 70%.',
                                    color: 'warning',
                                    position: 'top-center'
                                });
                                return;
                            }
                        }

                        if(this.isSFV) {
                            this.onboardingStep["7_finance_details"].loan_term = this.loanTerm * 12; // Años a meses
                        } else {
                            this.onboardingStep["7_finance_details"].loan_term = this.loanTerm;
                        }
                        this.onboardingStep["7_finance_details"].initial_amount = null;
                        this.onboardingStep["7_finance_details"].initial_percent = null;
                        // this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Información adicional',
                            text: 'Para continuar debes seleccionar el periodo para pagar tu crédito.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }

                    // AQUÍ DEBERÍA MANDAR AL COMPONENTE DE LA SOLICITUD
                    this.postLoanRequest();
                    break;
                case '8_set_money_loan_info':

                    const loanAmount = this.unMoneyFormat(this.loanAmount);
                    
                    if(loanAmount < this.calculateMinAmount || loanAmount > this.calculateMaxAmount) {
                        this.$vs.notify({
                            title: 'Monto del crédito',
                            text: 'El monto solicitado debe estar dentro del rango de financiamiento.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }

                    if(loanAmount != null && this.limitedTerms != null) {
                        this.onboardingStep["8_money_loan_info"].loan_amount = loanAmount;
                        this.onboardingStep["8_money_loan_info"].loan_term = this.limitedTerms * 12; // Años a meses
                        // this.onboardingStep["4_supplier_information"].supplier_id = supplierId;
                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Información adicional',
                            text: 'Para continuar debes seleccionar el monto y el periodo para pagar tu crédito.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    break;
                case '9_improve_business_desc':
                    if(this.isPersonalLoan) {
                        if(this.loan_use_reason != "") {
                            this.onboardingStep["9_destination_of_resources"].loan_use_reason = this.loan_use_reason;
                            this.step++;
                        } else {
                            this.$vs.notify({
                                title: 'Información adicional',
                                text: 'Para continuar platícanos cómo mejorarías tu negocio con este crédito.',
                                color: 'warning',
                                position: 'top-center'
                            });
                            return;
                        }

                    }else {
                        if(this.improve_business != "") {
                            this.onboardingStep["9_destination_of_resources"].improve_business = this.improve_business;
                            this.step++;
                        } else {
                            this.$vs.notify({
                                title: 'Información adicional',
                                text: 'Para continuar platícanos cómo mejorarías tu negocio con este crédito.',
                                color: 'warning',
                                position: 'top-center'
                            });
                            return;
                        }
                    }
                    break;
                case '10_improve_social_desc':
                    if(this.improve_social != "") {
                        this.onboardingStep["9_destination_of_resources"].improve_social = this.improve_social;
                        // this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Información adicional',
                            text: 'Para continuar platícanos como a través de tus operaciones o proyecto generas beneficios ambientales y/o sociales.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                    // AQUÍ DEBERÍA MANDAR AL COMPONENTE DE LA SOLICITUD
                    this.postLoanRequest();
                    break;
                case '9_loan_use_reason_desc':
                    if(this.loan_use_reason != "") {
                        this.onboardingStep["9_destination_of_resources"].loan_use_reason = this.loan_use_reason;
                        this.step++;
                    } else {
                        this.$vs.notify({
                            title: 'Información adicional',
                            text: 'Para continuar platícanos cómo mejorarías tu negocio con este crédito.',
                            color: 'warning',
                            position: 'top-center'
                        });
                        return;
                    }
                default:
                    break;
            }
        },
        async prevStep(step) {
            switch(step) {
                case '1_set_loan_type':
                    this.onboardingStep["1_loan_type"] = null;
                    this.onboardingStep["2_person_type"] = null;
                    this.loanType = null;
                    this.personType = null;
                    if(this.loanType == 3) {
                        this.step--;
                    }
                    break;
                case '2_set_person_type':
                    this.onboardingStep["1_loan_type"] = null;
                    // if(this.isPersonalLoan) {
                    //     this.onboardingStep["2_person_type"] = null;
                    //     this.loanType = null;
                    // }
                    break;
                case '3_set_family_group':
                    this.onboardingStep["3_family_group"] = null;
                    break;
                case '4_set_supplier_information':
                    // this.onboardingStep["3_family_group"] = null;
                    this.onboardingStep["4_supplier_information"] = {
                        supplier_name: null,
                        supplier_phone: null,
                        supplier_id: null,
                    }
                    if(!this.isSFV) {
                        this.onboardingStep["3_family_group"] = null;
                    }
                    break;
                case '6_set_project_values':
                    this.onboardingStep["6_project_details"].cfe_value = null; 
                    this.onboardingStep["6_project_details"].product_cost = null;
                    break;
                case '8_set_money_loan_info':
                    this.onboardingStep["8_money_loan_info"].loan_amount = null; 
                    this.onboardingStep["8_money_loan_info"].loan_term = null;
                    break;
                case '9_improve_business_desc':
                    if(this.isPersonalLoan) {
                        this.onboardingStep["9_destination_of_resources"].loan_use_reason = null;
                    } else {
                        this.onboardingStep["9_destination_of_resources"].improve_business = null;
                    }
                    break;
                case "9_loan_use_reason_desc":
                    this.onboardingStep["9_destination_of_resources"].loan_use_reason = null;
                    break;
                default:
                    break;
            }
            this.step--;
        },
        async getCollections(){
            try {
                let collectionsObjects = ['familyGroups', 'banxicoUdis', 'banxicoMxnUsdExchange'];

                let slasObjects = [
                    'min_financed_amount_mxn',
                    'max_financed_amount_mxn',
                    'max_applicant_pf_financed_amount_udis',
                    'max_first_project_supplier_total_cost_mxn',
                    'max_applicant_pm_financed_amount_udis',
                    'min_financed_amount_udis',
                    'max_financed_amount_mxn'
                ];

                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                this.collections.family_groups = res.data.familyGroups;
                this.udis = res.data.banxicoUdis.udis;
                this.exchange_mxn_usd = res.data.banxicoMxnUsdExchange[0].exchange_mxn_usd;

                let slasParams = "with[]=" + slasObjects.join("&with[]=");
                let slasRes = await axios.get(`/api/v1/forms/getSlasValues?${slasParams}`);
                this.slas = slasRes.data;
                this.maxAmount = this.LoanLimits.max_limit;
                // if(this.isPFAE && this.isPersonalLoan) {
                //     this.maxAmount = this.slas.max_applicant_pf_financed_amount_udis * this.udis;
                // }
            }
            catch (e) {
                console.log(e);
                this.warn(e);
                this.failed = true;
            }
        },
        async postLoanRequest() {
            this.doLoanRequest();
            // this.$vs.dialog({
            //     color: "warning",
            //     type: "confirm",
            //     title: `¡Solicita tu crédito ${this.getLoanTypeNamed}!`,
            //     text: `Ya nos has proporcionaste la información básica para comenzar tu solicitud de <<crédito ${this.getLoanTypeNamed}>>, al aceptar un asesor de RedGirasol la revisará y se pondrá en contacto contigo para continuar con el proceso.`,
            //     acceptText: "Crear solicitud",
            //     cancelText: 'Cancelar',
            //     accept: this.doLoanRequest,
            //     cancel: false,
            //     close: false,
            // })
        },        
        async doLoanRequest() {
            try {
                this.showLoading(true, `¡Solicitando tu Crédito ${this.getLoanTypeNamed}!`);
                const res = await axios.post(`/api/loan-onboarding/post/${this.ApplicantId}/create-loan/${this.apiPostRoute}`, this.onboardingStep);
                this.showLoading(false);
                this.loanIdCreated = res.data.loanId;
                // this.successLoanDialog();
                this.goToProject();
            } catch (error) {
                this.showLoading(false);
                console.log(error);
                this.failedOperationNotif();
            }
        },        
        async successLoanDialog() {
            this.$vs.dialog({
                color: "success",
                title: `¡Solicitaste un Crédito ${this.getLoanTypeNamed}!`,
                text: `¡Felicidades! Tu solicitud fue creada con éxito; para continuar, platícanos más sobre ti.`,
                acceptText: "Continuar",
                accept: this.goToProject,
                cancel: this.goToHomePage,
                close: this.goToHomePage,
            })
        },
        goToProject() {
            this.$router.replace({
                name: "applicantLoanDetail",
                params: { id: this.loanIdCreated }
            });
        },
        async goToHomePage(){
            await this.$router.push({name: 'inicioSolicitante'});
        },
        onSelect({ dialCode }) {
            this.country_calling_code = `+${dialCode}`;
        },
        async validateForm() {
            return this.$validator.validateAll();
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        showLoanOptions(item)
        {
            if(this.isPF) {
                return item.value != 3;
            } else if(this.isPFAE) {
                return item.value != 2;
            } else if(this.isMoral) {
                return item.value != 2;
            }
        },
        showOptionByProject(item){
            if(item.value == 1) {
                return !this.hasEcoTechnologies;
            } else if(item.value == 2) {
                return !this.hasPersonal;
            } else if(item.value == 3) {
                return !this.hasPYME;

            }
        },
        calculateLoanAmountByCFEValue() {
            // Calculamos Potencia en W
            const  power = ((this.cfeValue/efficiency)/irradiation/daysInYear) * 1000 ;

            // Calculamos el precio watt en peso mxn + iva
            const priceWatt = pricePerWatDlls * this.exchange_mxn_usd * tax;

            // Calculamos el costo total
            const totalCost = power * priceWatt;
            this.productValue = this.format_price(totalCost);
            this.$forceUpdate();
        }
    }
}
</script>

<style>
.big-title-bg {
  font-size: 3rem !important;
}

.border-left {
    border-left: 2px solid #f2f2f2;
}

.border-solid {
    border-width: 2px;
    border-radius: 10px;
    padding: 1rem;
}

.range-tags {
    display: flex !important;
    justify-content: space-between;
}

.custom-range input::before {
    top: 5px !important;
    background-color: #28DE18;
}

input {
    height: auto !important;
}

.not-suere-btn {
    color: #28de18;
    text-decoration: underline;
    font-size: medium;
}


</style>